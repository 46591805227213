























































































import { Component } from 'vue-property-decorator';
import Footer from '../components/Footer.vue'
import AbpBase from '../lib/abpbase'
import Util from '@/lib/util';

@Component({
    components: { Footer },    
})
export default class Login extends AbpBase {
    name: string = 'login';
    loading: boolean = false;  
    valid: boolean = false;  
    forgot: boolean = false;
    requesting: boolean = false;
    passwordType: string = 'password';  
    passwordIcon: string = 'mdi-eye-off';  
    loginModel = {
        username:'',
        password:'',
        remember_me:false
    }
    async login() {
        if ((this.$refs.loginform as any).validate()) {
            this.loading = true;
            
            let result = await this.$store.dispatch({
                type: 'app/login',
                data: this.loginModel
            });

            if (result) {
                sessionStorage.setItem('remember_me', this.loginModel.remember_me ? '1' : '0');
                location.reload();
            }
            this.loading = false;
        }      
    }
    
    async sendCode() {
        if ((this.$refs.resetform as any).validate()) {

            let result = await this.$store.dispatch({
                type: 'user/sendResetCode',
                data: {
                    username: this.loginModel.username
                }
            });

            this.requesting = true;
        }
    }

    rules = {
        username: [v => !!v || this.L('Usuario') + ' ' + this.L('es obligatorio')],
        password: [v => !!v || this.L('Password') + ' ' + this.L('es obligatorio')]
    }

    toggePassword() {
        this.passwordIcon = (this.passwordIcon == 'mdi-eye-off' ? 'mdi-eye' : 'mdi-eye-off');
        this.passwordType = (this.passwordType == 'password' ? 'text' : 'password');
    }

    created(){
    } 
}
